var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showSearch,
              expression: "showSearch",
            },
          ],
          ref: "queryForm",
          attrs: {
            model: _vm.queryParams,
            size: "small",
            inline: true,
            "label-width": "68px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "规格搜索", prop: "specification" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入关键字", clearable: "" },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleQuery($event)
                  },
                },
                model: {
                  value: _vm.queryParams.specification,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryParams, "specification", $$v)
                  },
                  expression: "queryParams.specification",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    icon: "el-icon-search",
                    size: "mini",
                  },
                  on: { click: _vm.handleQuery },
                },
                [_vm._v("搜索")]
              ),
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-refresh", size: "mini" },
                  on: { click: _vm.resetQuery },
                },
                [_vm._v("重置")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "mb8", attrs: { gutter: 10 } },
        [
          _c(
            "el-col",
            { attrs: { span: 1.5 } },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "hasPermi",
                      rawName: "v-hasPermi",
                      value: ["goods:sku:add"],
                      expression: "['goods:sku:add']",
                    },
                  ],
                  attrs: {
                    type: "primary",
                    plain: "",
                    icon: "el-icon-plus",
                    size: "mini",
                  },
                  on: { click: _vm.handleAdd },
                },
                [_vm._v("新增")]
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 1.5 } },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "hasPermi",
                      rawName: "v-hasPermi",
                      value: ["goods:sku:edit"],
                      expression: "['goods:sku:edit']",
                    },
                  ],
                  attrs: {
                    type: "success",
                    plain: "",
                    icon: "el-icon-edit",
                    size: "mini",
                    disabled: _vm.single,
                  },
                  on: { click: _vm.handleUpdate },
                },
                [_vm._v("修改")]
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 1.5 } },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "hasPermi",
                      rawName: "v-hasPermi",
                      value: ["system:sku:remove"],
                      expression: "['system:sku:remove']",
                    },
                  ],
                  attrs: {
                    type: "danger",
                    plain: "",
                    icon: "el-icon-delete",
                    size: "mini",
                    disabled: _vm.multiple,
                  },
                  on: { click: _vm.handleDelete },
                },
                [_vm._v("删除")]
              ),
            ],
            1
          ),
          _c("right-toolbar", {
            attrs: { showSearch: _vm.showSearch },
            on: {
              "update:showSearch": function ($event) {
                _vm.showSearch = $event
              },
              "update:show-search": function ($event) {
                _vm.showSearch = $event
              },
              queryTable: _vm.getList,
            },
          }),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { data: _vm.skuList },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", {
            attrs: { type: "selection", width: "55", align: "center" },
          }),
          _c("el-table-column", {
            attrs: {
              label: "商品",
              align: "center",
              width: "200",
              prop: "goodsName",
            },
          }),
          _c("el-table-column", {
            attrs: { label: "规格名", align: "center", prop: "name" },
          }),
          _c("el-table-column", {
            attrs: {
              label: "属性描述",
              align: "center",
              prop: "specification",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "限量",
              align: "center",
              width: "80",
              prop: "saleLimit",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.saleLimit
                      ? _c("el-tag", { attrs: { type: "warning" } }, [
                          _vm._v("限量"),
                        ])
                      : _c("el-tag", { attrs: { type: "success" } }, [
                          _vm._v("不限"),
                        ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "库存",
              align: "center",
              width: "80",
              prop: "stock",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "价格",
              align: "center",
              width: "80",
              prop: "price",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "销量",
              align: "center",
              width: "80",
              prop: "sales",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              align: "center",
              width: "160",
              "class-name": "small-padding fixed-width",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "hasPermi",
                            rawName: "v-hasPermi",
                            value: ["goods:sku:edit"],
                            expression: "['goods:sku:edit']",
                          },
                        ],
                        attrs: {
                          size: "mini",
                          type: "text",
                          icon: "el-icon-edit",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleUpdate(scope.row)
                          },
                        },
                      },
                      [_vm._v("修改")]
                    ),
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "hasPermi",
                            rawName: "v-hasPermi",
                            value: ["goods:sku:remove"],
                            expression: "['goods:sku:remove']",
                          },
                        ],
                        staticClass: "text-warning",
                        attrs: {
                          size: "mini",
                          type: "text",
                          icon: "el-icon-delete",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleDelete(scope.row)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.title,
            visible: _vm.open,
            width: "500px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.open = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "80px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "商品", prop: "goodsName" } },
                [_vm._v(" " + _vm._s(_vm.form.goodsName) + " ")]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "属性描述", prop: "specification" } },
                [_vm._v(" " + _vm._s(_vm.form.specification) + " ")]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "名称", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入商品名称" },
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "是否限量", prop: "saleLimit" } },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-value": 1,
                      "active-text": "限量",
                      "inactive-value": 0,
                      "inactive-text": "不限量",
                    },
                    model: {
                      value: _vm.form.saleLimit,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "saleLimit", $$v)
                      },
                      expression: "form.saleLimit",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "价格", prop: "price" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入价格" },
                    model: {
                      value: _vm.form.price,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "price", $$v)
                      },
                      expression: "form.price",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.updateSku } },
                [_vm._v("确 定")]
              ),
              _c("el-button", { on: { click: _vm.cancelUpdate } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            visible: _vm.dialogAddSku,
            "destroy-on-close": true,
            direction: "rtl",
            size: "40%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogAddSku = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { padding: "0 5px" } },
            [
              _c("QuickSku", {
                ref: "skuForm",
                attrs: {
                  "source-attribute": _vm.sourceAttribute,
                  structure: _vm.structure,
                  attribute: _vm.attribute,
                  sku: _vm.sku,
                  "remove-btn": _vm.changeAttrValid,
                  "add-btn": _vm.changeAttrValid,
                },
                on: {
                  "update:attribute": function ($event) {
                    _vm.attribute = $event
                  },
                  "update:sku": function ($event) {
                    _vm.sku = $event
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "score",
                    fn: function (slotProps) {
                      return [
                        _c(
                          "div",
                          [
                            _c("el-rate", {
                              model: {
                                value: slotProps.row.score,
                                callback: function ($$v) {
                                  _vm.$set(slotProps.row, "score", $$v)
                                },
                                expression: "slotProps.row.score",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-top": "10px", width: "100%" },
                          attrs: { type: "primary" },
                          on: { click: _vm.submitAdd },
                        },
                        [_vm._v("确认添加")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-top": "10px", width: "100%" },
                          on: { click: _vm.cancelAdd },
                        },
                        [_vm._v("取消")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }