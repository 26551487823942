<template>
  <div :class="className" :style="{height:height,width:width}" />
</template>

<script>
import * as echarts from 'echarts';
require('echarts/theme/macarons') // echarts theme
import resize from './mixins/resize'

export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '350px'
    },
    autoResize: {
      type: Boolean,
      default: true
    },
    chartData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      chart: null
    }
  },
  watch: {
    chartData: {
      deep: true,
      handler(val) {
        this.setOptions(val)
      }
    }
  },
  mounted() {
    //todo 获取远端数据
    this.$nextTick(() => {
      this.initChart()
    })
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$el, 'macarons')
      this.setOptions(this.chartData)
    },
    setOptions({ countTitle,horizontalAxis,countData } = {}) {
      this.chart.setOption({
        xAxis: {
          data: horizontalAxis,
          boundaryGap: false,
          axisTick: {
            show: false
          }
        },
        grid: {
          left: 20,
          right: 40,
          bottom: 20,
          top: 30,
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          },
          padding: [5, 10]
        },
        yAxis: {
          axisTick: {
            show: false
          }
        },
        legend: {
          data: countTitle,
          type: 'scroll',
          orient: 'horizontal'
        },
        series: (function() {
          const series = [];
          for (let i = 0; i < countData.length; i++) {
            const item = {
              name: countTitle[i],
              itemStyle: {
                normal: {
                  // color: 'black',
                  lineStyle: {
                    color: '#' + Math.floor(Math.random()*16777215).toString(16),
                    width: 2
                  }
                }
              },
              smooth: true,
              symbol: 'none',
              type: 'line',
              animationDuration: 2800,
              animationEasing: 'cubicInOut',
              data: countData[i]
            };
            series.push(item);
          }
          return series;
        })()
      })
    }
  }
}
</script>
