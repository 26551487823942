import request from '@/utils/request'
// 分页查询设备分组
export function pageGroup(query) {
  return request({
    url: '/common/group/page',
    method: 'get',
    params: query
  })
}

// 查询设备分组列表
export function listGroup(query) {
  return request({
    url: '/common/group/list',
    method: 'get',
    params: query
  })
}

// 查询设备分组详细
export function getGroup(id) {
  return request({
    url: '/common/group/' + id,
    method: 'get'
  })
}

// 新增设备分组
export function addGroup(data) {
  return request({
    url: '/common/group',
    method: 'post',
    data: data
  })
}

// 修改设备分组
export function updateGroup(data) {
  return request({
    url: '/common/group',
    method: 'put',
    data: data
  })
}

// 删除设备分组
export function delGroup(id) {
  return request({
    url: '/common/group/' + id,
    method: 'delete'
  })
}

export function leaveGroup(data) {
  return request({
    url: '/common/group/leave',
    method: 'post',
    data: data
  })
}
export function joinGroup(data) {
  return request({
    url: '/common/group/join',
    method: 'post',
    data: data
  })
}


