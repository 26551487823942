var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sku-container" },
    [
      !_vm.disabled
        ? _c(
            "div",
            { staticClass: "sku-check" },
            [
              _vm.theme === 1
                ? _c(
                    "div",
                    { staticClass: "theme-1" },
                    _vm._l(_vm.myAttribute, function (item, index) {
                      return _c(
                        "el-card",
                        {
                          key: index,
                          staticClass: "item",
                          attrs: { shadow: "never" },
                        },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "header" }, slot: "header" },
                            [
                              _c("span", [_vm._v(_vm._s(item.name))]),
                              _vm.removeBtn
                                ? _c(
                                    "el-button",
                                    {
                                      staticStyle: {
                                        float: "right",
                                        padding: "3px",
                                      },
                                      attrs: { icon: "el-icon-delete" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.removeAttr(item.name)
                                        },
                                      },
                                    },
                                    [_vm._v("删除")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm._l(item.item, function (item2, index2) {
                            return _c("el-checkbox", {
                              key: index2,
                              attrs: { label: item2.name, size: "small" },
                              model: {
                                value: item2.checked,
                                callback: function ($$v) {
                                  _vm.$set(item2, "checked", $$v)
                                },
                                expression: "item2.checked",
                              },
                            })
                          }),
                          item.canAddAttribute
                            ? _c(
                                "el-input",
                                {
                                  staticClass: "add-attr",
                                  attrs: {
                                    size: "small",
                                    placeholder: "新增一个规格",
                                  },
                                  nativeOn: {
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                      return _vm.onAddAttribute(index)
                                    },
                                  },
                                  model: {
                                    value: item.addAttribute,
                                    callback: function ($$v) {
                                      _vm.$set(item, "addAttribute", $$v)
                                    },
                                    expression: "item.addAttribute",
                                  },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        slot: "append",
                                        size: "small",
                                        icon: "el-icon-plus",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onAddAttribute(index)
                                        },
                                      },
                                      slot: "append",
                                    },
                                    [_vm._v("添加")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        2
                      )
                    }),
                    1
                  )
                : _c(
                    "el-table",
                    {
                      staticClass: "theme-2",
                      attrs: { data: _vm.myAttribute, "show-header": false },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { prop: "name", width: "120", resizable: false },
                      }),
                      _c("el-table-column", {
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return _vm._l(
                                  scope.row.item,
                                  function (item2, index2) {
                                    return _c("el-checkbox", {
                                      key: index2,
                                      attrs: {
                                        label: item2.name,
                                        size: "small",
                                      },
                                      model: {
                                        value: item2.checked,
                                        callback: function ($$v) {
                                          _vm.$set(item2, "checked", $$v)
                                        },
                                        expression: "item2.checked",
                                      },
                                    })
                                  }
                                )
                              },
                            },
                          ],
                          null,
                          false,
                          904863973
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: { width: "250" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-input",
                                    {
                                      staticClass: "add-attr",
                                      attrs: {
                                        size: "small",
                                        placeholder: "新增一个规格",
                                      },
                                      nativeOn: {
                                        keyup: function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          ) {
                                            return null
                                          }
                                          return _vm.onAddAttribute(
                                            scope.$index
                                          )
                                        },
                                      },
                                      model: {
                                        value: scope.row.addAttribute,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            scope.row,
                                            "addAttribute",
                                            $$v
                                          )
                                        },
                                        expression: "scope.row.addAttribute",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            slot: "append",
                                            size: "small",
                                            icon: "el-icon-plus",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.onAddAttribute(
                                                scope.$index
                                              )
                                            },
                                          },
                                          slot: "append",
                                        },
                                        [_vm._v("添加")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2078934451
                        ),
                      }),
                    ],
                    1
                  ),
            ],
            1
          )
        : _vm._e(),
      _vm.sourceAttribute.length < _vm.maxAttrNum && _vm.addBtn
        ? _c(
            "el-divider",
            [
              _c(
                "el-input",
                {
                  staticClass: "input-with-select",
                  attrs: { placeholder: "输入新的属性" },
                  model: {
                    value: _vm.newAttr,
                    callback: function ($$v) {
                      _vm.newAttr = $$v
                    },
                    expression: "newAttr",
                  },
                },
                [
                  _c("template", { slot: "prepend" }, [_vm._v("新属性")]),
                  _c(
                    "el-button",
                    {
                      attrs: { slot: "append", icon: "el-icon-plus" },
                      on: { click: _vm.appendAttribute },
                      slot: "append",
                    },
                    [_vm._v("添加")]
                  ),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "sku-list" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                "status-icon": "",
                "inline-message": "",
              },
            },
            [
              _c(
                "el-table",
                {
                  attrs: {
                    data: _vm.form.skuData,
                    stripe: "",
                    border: "",
                    "highlight-current-row": "",
                  },
                },
                [
                  _vm.emitAttribute.length > 0
                    ? _c("el-table-column", {
                        attrs: {
                          type: "index",
                          width: "56",
                          align: "center",
                          resizable: false,
                        },
                      })
                    : _vm._e(),
                  _vm._l(_vm.emitAttribute, function (attr, index) {
                    return _c("el-table-column", {
                      key: "attribute-" + index,
                      attrs: {
                        label: attr.name,
                        prop: attr.name,
                        width: "120",
                        align: "center",
                        resizable: false,
                      },
                    })
                  }),
                  _vm._l(_vm.structure, function (item, index) {
                    return _c(
                      "el-table-column",
                      {
                        key: "structure-" + index,
                        attrs: {
                          label: item.label,
                          prop: item.name,
                          align: "center",
                          resizable: false,
                          "min-width": "120px",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  item.type === "input"
                                    ? _c(
                                        "el-form-item",
                                        {
                                          key:
                                            "structure-input-" +
                                            index +
                                            "-" +
                                            scope.row.sku,
                                          attrs: {
                                            prop:
                                              "skuData." +
                                              scope.$index +
                                              "." +
                                              item.name,
                                            rules: _vm.rules[item.name],
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              placeholder:
                                                "请输入" + item.label,
                                              size: "small",
                                            },
                                            model: {
                                              value: scope.row[item.name],
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  item.name,
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scope.row[item.name]",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : item.type === "slot"
                                    ? _c(
                                        "el-form-item",
                                        {
                                          key:
                                            "structure-input-" +
                                            index +
                                            "-" +
                                            scope.row.sku,
                                          attrs: {
                                            prop:
                                              "skuData." +
                                              scope.$index +
                                              "." +
                                              item.name,
                                            rules: _vm.rules[item.name],
                                          },
                                        },
                                        [
                                          _vm._t(item.name, null, {
                                            $index: scope.$index,
                                            row: scope.row,
                                            column: scope.column,
                                          }),
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [
                        _c(
                          "template",
                          { slot: "header" },
                          [
                            _c(
                              "span",
                              { class: { required_title: item.required } },
                              [_vm._v(" " + _vm._s(item.label) + " ")]
                            ),
                            item.tip
                              ? _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      effect: "dark",
                                      content: item.tip,
                                      placement: "top",
                                    },
                                  },
                                  [_c("i", { staticClass: "el-icon-info" })]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      2
                    )
                  }),
                  _vm.isBatch && _vm.form.skuData.length > 2
                    ? _c(
                        "template",
                        { slot: "append" },
                        [
                          _c(
                            "el-table",
                            { attrs: { data: [{}], "show-header": false } },
                            [
                              _c(
                                "el-table-column",
                                {
                                  attrs: {
                                    width: _vm.attribute.length * 120 + 50,
                                    align: "center",
                                    resizable: false,
                                  },
                                },
                                [_vm._v("批量设置")]
                              ),
                              _vm._l(_vm.structure, function (item, index) {
                                return _c(
                                  "el-table-column",
                                  {
                                    key: "batch-structure-" + index,
                                    attrs: {
                                      align: "center",
                                      resizable: false,
                                      "min-width": "120px",
                                    },
                                  },
                                  [
                                    item.type === "input" &&
                                    item.batch !== false
                                      ? _c("el-input", {
                                          attrs: {
                                            placeholder:
                                              "填写一个" + item.label,
                                            size: "small",
                                          },
                                          nativeOn: {
                                            keyup: function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              ) {
                                                return null
                                              }
                                              return _vm.onBatchSet(item.name)
                                            },
                                          },
                                          model: {
                                            value: _vm.batch[item.name],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.batch,
                                                item.name,
                                                $$v
                                              )
                                            },
                                            expression: "batch[item.name]",
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }