<template>
  <el-row :gutter="40" class="panel-group">
    <el-col :xs="12" :sm="12" :lg="6" class="card-panel-col">
      <div class="card-panel" @click="handleSetLineChartData('user')">
        <div class="card-panel-icon-wrapper icon-user" :style="{'border':this.panelSel==='user'?'2px solid #40c9c6':'none'}">
          <svg-icon icon-class="peoples" class-name="card-panel-icon" />
        </div>
        <div class="card-panel-description">
          <div class="card-panel-text">
            注册用户
          </div>
          <count-to :start-val="0" :end-val="this.totalUser[0]" :duration="5000" class="card-panel-num" />
        </div>
      </div>
    </el-col>
    <el-col :xs="12" :sm="12" :lg="6" class="card-panel-col">
      <div class="card-panel" @click="handleSetLineChartData('device')">
        <div class="card-panel-icon-wrapper icon-device" :style="{'border':this.panelSel==='device'?'2px solid #36a3f7':'none'}">
          <svg-icon icon-class="device" class-name="card-panel-icon" />
        </div>
        <div class="card-panel-description">
          <div class="card-panel-text">
            注册设备
          </div>
          <count-to :start-val="0" :end-val="this.totalDevice[0]" :duration="5000" class="card-panel-num" />
        </div>
      </div>
    </el-col>
    <el-col :xs="12" :sm="12" :lg="6" class="card-panel-col">
      <div class="card-panel" @click="handleSetLineChartData('report')">
        <div class="card-panel-icon-wrapper icon-report" :style="{'border':this.panelSel==='report'?'2px solid #3A71A8':'none'}">
          <svg-icon icon-class="report" class-name="card-panel-icon" />
        </div>
        <div class="card-panel-description">
          <div class="card-panel-text">
            报告上传
          </div>
          <count-to :start-val="0" :end-val="this.totalReport[0]" :duration="5000" class="card-panel-num" />
        </div>
      </div>
    </el-col>
    <el-col :xs="12" :sm="12" :lg="6" class="card-panel-col">
      <div class="card-panel" @click="handleSetLineChartData('article')">
        <div class="card-panel-icon-wrapper icon-article" :style="{'border':this.panelSel==='article'?'2px solid #34bfa3':'none'}">
          <svg-icon icon-class="article" class-name="card-panel-icon" />
        </div>
        <div class="card-panel-description">
          <div class="card-panel-text">
            文章新增
          </div>
          <count-to :start-val="0" :end-val="this.totalArticle[0]" :duration="5000" class="card-panel-num" />
        </div>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import CountTo from 'vue-count-to'

export default {
  components: {
    CountTo
  },
  props: {
    totalUser: {
      type: Array,
      required: true
    },
    totalDevice: {
      type: Array,
      required: true
    },
    totalReport: {
      type: Array,
      required: true
    },
    totalArticle: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
      panelSel: 'user',
    }
  },
  methods: {
    handleSetLineChartData(type) {
      this.panelSel = type
      this.$emit('handleSetLineChartData', type)
    }
  }
}
</script>

<style lang="scss" scoped>
.panel-select {
  color: green;
  border: 1px solid green;
}
.panel-group {
  margin-top: 18px;

  .card-panel-col {
    margin-bottom: 32px;
  }

  .card-panel {
    height: 108px;
    cursor: pointer;
    font-size: 12px;
    position: relative;
    overflow: hidden;
    color: #666;
    background: #fff;
    box-shadow: 4px 4px 40px rgba(0, 0, 0, .05);
    border-color: rgba(0, 0, 0, .05);

    &:hover {
      .card-panel-icon-wrapper {
        color: #fff;
      }

      .icon-user {
        background: #40c9c6;
      }

      .icon-device {
        background: #36a3f7;
      }

      .icon-report {
        background: #3A71A8;
      }

      .icon-article {
        background: #34bfa3
      }
    }

    .icon-user {
      color: #40c9c6;
    }

    .icon-device {
      color: #36a3f7;
    }

    .icon-report {
      color: #3A71A8;
    }

    .icon-article {
      color: #34bfa3
    }

    .card-panel-icon-wrapper {
      float: left;
      margin: 14px 0 0 14px;
      padding: 16px;
      transition: all 0.38s ease-out;
      border-radius: 6px;
    }

    .card-panel-icon {
      float: left;
      font-size: 48px;
    }

    .card-panel-description {
      float: right;
      font-weight: bold;
      margin: 26px;
      margin-left: 0px;

      .card-panel-text {
        line-height: 18px;
        color: rgba(0, 0, 0, 0.45);
        font-size: 16px;
        margin-bottom: 12px;
      }

      .card-panel-num {
        font-size: 20px;
      }
      .card-panel-select {
        border: 2px solid orange;
        background-color: #7a6df0;
      }
    }
  }
}

@media (max-width:550px) {
  .card-panel-description {
    display: none;
  }

  .card-panel-icon-wrapper {
    float: none !important;
    width: 100%;
    height: 100%;
    margin: 0 !important;

    .svg-icon {
      display: block;
      margin: 14px auto !important;
      float: none !important;
    }
  }
}
</style>
