<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" size="small" :inline="true" v-show="showSearch" label-width="68px">
      <el-form-item label="规格搜索" prop="specification">
        <el-input
          v-model="queryParams.specification"
          placeholder="请输入关键字"
          clearable
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button
          type="primary"
          plain
          icon="el-icon-plus"
          size="mini"
          @click="handleAdd"
          v-hasPermi="['goods:sku:add']"
        >新增</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
          type="success"
          plain
          icon="el-icon-edit"
          size="mini"
          :disabled="single"
          @click="handleUpdate"
          v-hasPermi="['goods:sku:edit']"
        >修改</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
          type="danger"
          plain
          icon="el-icon-delete"
          size="mini"
          :disabled="multiple"
          @click="handleDelete"
          v-hasPermi="['system:sku:remove']"
        >删除</el-button>
      </el-col>
      <right-toolbar :showSearch.sync="showSearch" @queryTable="getList"></right-toolbar>
    </el-row>

    <el-table v-loading="loading" :data="skuList" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column label="商品" align="center" width="200" prop="goodsName" />
      <el-table-column label="规格名" align="center" prop="name" />
      <el-table-column label="属性描述" align="center" prop="specification" />
      <el-table-column label="限量" align="center" width="80" prop="saleLimit">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.saleLimit" type="warning">限量</el-tag>
          <el-tag v-else type="success">不限</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="库存" align="center" width="80" prop="stock" />
      <el-table-column label="价格" align="center" width="80" prop="price" />
      <el-table-column label="销量" align="center" width="80" prop="sales" />
      <el-table-column label="操作" align="center" width="160" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="text"
            icon="el-icon-edit"
            @click="handleUpdate(scope.row)"
            v-hasPermi="['goods:sku:edit']"
          >修改</el-button>
          <el-button
            class="text-warning"
            size="mini"
            type="text"
            icon="el-icon-delete"
            @click="handleDelete(scope.row)"
            v-hasPermi="['goods:sku:remove']"
          >删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 修改商品规格对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="500px" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="商品" prop="goodsName">
          {{form.goodsName}}
        </el-form-item>
        <el-form-item label="属性描述" prop="specification">
          {{form.specification}}
<!--          <el-input v-model="form.specification" disabled />-->
        </el-form-item>
        <el-form-item label="名称" prop="name">
          <el-input v-model="form.name" placeholder="请输入商品名称" />
        </el-form-item>
        <el-form-item label="是否限量" prop="saleLimit">
          <el-switch v-model="form.saleLimit" :active-value="1" active-text="限量" :inactive-value="0" inactive-text="不限量"/>
        </el-form-item>
<!--        <el-form-item label="库存" prop="stock">-->
<!--          <el-input v-model="form.stock" placeholder="请输入库存" />-->
<!--        </el-form-item>-->
        <el-form-item label="价格" prop="price">
          <el-input v-model="form.price" placeholder="请输入价格" />
        </el-form-item>
<!--        <el-form-item label="销量" prop="sales">-->
<!--          <el-input v-model="form.sales" placeholder="请输入销量" />-->
<!--        </el-form-item>-->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="updateSku">确 定</el-button>
        <el-button @click="cancelUpdate">取 消</el-button>
      </div>
    </el-dialog>
    <!--  增加规格弹窗  -->
    <el-drawer :visible.sync="dialogAddSku" :destroy-on-close="true" direction="rtl" size="40%">
<!--    <el-dialog :visible.sync="dialogAddSku" fullscreen close-on-press-escape append-to-body destroy-on-close>-->
      <div style="padding: 0 5px">
        <QuickSku
          ref="skuForm"
          :source-attribute="sourceAttribute"
          :structure="structure"
          :attribute.sync="attribute"
          :sku.sync="sku"
          :remove-btn="changeAttrValid"
          :add-btn="changeAttrValid"
        >
          <template #score="slotProps">
            <div>
              <el-rate v-model="slotProps.row.score" />
            </div>
          </template>
        </QuickSku>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-button type="primary" style="margin-top: 10px;width: 100%" @click="submitAdd">确认添加</el-button>
          </el-col>
          <el-col :span="12">
            <el-button style="margin-top: 10px;width: 100%" @click="cancelAdd">取消</el-button>
          </el-col>
        </el-row>
      </div>
<!--    </el-dialog>-->
    </el-drawer>
  </div>
</template>

<script>
import { listSku, getSku, delSku, addSku, updateSku, batchUpdateSku, struct } from '@/api/order/goods/goodsSku'
import { getGoods } from '@/api/order/goods/goods'
import QuickSku from '@/components/SkuForm/QuickSku.vue'

export default {
  name: "Sku",
  components: { QuickSku },
  data() {
    return {
      sourceAttribute:[],
      sku:[],
      attribute: [],
      structure: [
        {
          name: 'price',
          type: 'input',
          label: '价格',
          required: true
        }
      ],
      goods: null,
      dialogAddSku: false,
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 商品规格表格数据
      skuList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        goodsId: null,
        specification: null
      },
      // 表单参数
      form: {},
      // 表单校验
      rules: {},
      changeAttrValid: false,
    };
  },
  computed: {
  },
  watch: {
    goods: function(info){
      this.changeAttrValid = info.type !== 'CHECK_ITEM'
    }
  },
  created() {
    this.queryParams.goodsId = parseInt(this.$route.params && this.$route.params.goodsId);
    this.getList();
    this.getGoodsInfo();
  },
  methods: {
    getGoodsInfo() {
      getGoods(this.queryParams.goodsId).then(response => {
        this.goods = response.data;
      });
    },
    submitAdd() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.$refs.skuForm.validate(valid => {
        if (valid) {
          for (let i = 0; i < this.sku.length; i++) {
            if (this.sku[i].sku === ""){
              this.$message.warning('无效的规格配置')
              loading.close();
              return;
            }
          }
          //组建参数
          let param = {
            goodsId: this.goods.id,
            skuList: this.sku
          }
          //提交到后台处理更新
          batchUpdateSku(param).then(response => {
            this.sourceAttribute = [];
            this.sku = [];
            this.attribute = [];
            this.dialogAddSku = false;
            this.getList();
          });
        } else {
          this.$message.warning('验证失败')
        }
        loading.close();
      })
    },
    /** 查询商品规格列表 */
    getList() {
      this.loading = true;
      listSku(this.queryParams).then(response => {
        this.skuList = response.data;
        this.loading = false;
      });
    },
    // 取消按钮
    cancelUpdate() {
      this.open = false;
      this.reset();
    },
    // 取消按钮
    cancelAdd() {
      this.dialogAddSku = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        id: null,
        goodsId: null,
        specification: null,
        saleLimit: null,
        stock: null,
        price: null,
        sales: null
      };
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length!==1
      this.multiple = !selection.length
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.dialogAddSku = true;
      this.title = "添加商品规格";
      //从远端获取商品规格组成信息
      struct(this.queryParams.goodsId).then(response => {
        this.sourceAttribute = response.data.attributeList
        this.$refs.skuForm.init()
      })
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      const id = row.id || this.ids
      getSku(id).then(response => {
        this.form = response.data;
        this.open = true;
        this.title = "修改商品规格";
      });
    },
    /** 提交按钮 */
    updateSku() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.form.id != null) {
            updateSku(this.form).then(response => {
              this.$modal.msgSuccess("修改成功");
              this.open = false;
              this.getList();
            });
          } else {
            addSku(this.form).then(response => {
              this.$modal.msgSuccess("新增成功");
              this.open = false;
              this.getList();
            });
          }
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id || this.ids;
      this.$modal.confirm('是否确认删除商品规格编号为"' + ids + '"的数据项？').then(function() {
        return delSku(ids);
      }).then(() => {
        this.getList();
        this.$modal.msgSuccess("删除成功");
      }).catch(() => {});
    },
    /** 导出按钮操作 */
    handleExport() {
      this.download('system/sku/export', {
        ...this.queryParams
      }, `sku_${new Date().getTime()}.xlsx`)
    }
  }
};
</script>
