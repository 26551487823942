var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard-editor-container" },
    [
      _c("panel-group", {
        attrs: {
          "total-user": this.totalUser,
          "total-device": this.totalDevice,
          "total-report": this.totalReport,
          "total-article": this.totalArticle,
        },
        on: { handleSetLineChartData: _vm.handleSetLineChartData },
      }),
      _c(
        "el-row",
        {
          staticStyle: {
            background: "#fff",
            padding: "16px 16px 0",
            "margin-bottom": "32px",
          },
        },
        [_c("line-chart", { attrs: { "chart-data": _vm.lineChartData } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }