import request from '@/utils/request'

// 查询商品列表
export function listGoods(query) {
  return request({
    url: '/goods/list',
    method: 'get',
    params: query
  })
}

// 分页查询商品
export function pageGoods(query) {
  return request({
    url: '/goods/page',
    method: 'get',
    params: query
  })
}

// 查询商品详细
export function getGoods(id) {
  return request({
    url: '/goods/' + id,
    method: 'get'
  })
}

// 新增商品
export function addGoods(data) {
  return request({
    url: '/goods',
    method: 'post',
    data: data
  })
}

// 修改商品
export function updateGoods(data) {
  return request({
    url: '/goods',
    method: 'put',
    data: data
  })
}

// 删除商品
export function delGoods(id) {
  return request({
    url: '/goods/' + id,
    method: 'delete'
  })
}

// 查询商品分类枚举
export function typeMap() {
  return request({
    url: '/goods/typeEnums',
    method: 'get'
  })
}
