var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "form",
      attrs: { model: _vm.user, rules: _vm.rules, "label-width": "80px" },
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "用户昵称", prop: "nickName" } },
        [
          _c("el-input", {
            attrs: { maxlength: "30" },
            model: {
              value: _vm.user.nickName,
              callback: function ($$v) {
                _vm.$set(_vm.user, "nickName", $$v)
              },
              expression: "user.nickName",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "手机号码", prop: "phonenumber" } },
        [
          _c("el-input", {
            attrs: { maxlength: "11" },
            model: {
              value: _vm.user.phonenumber,
              callback: function ($$v) {
                _vm.$set(_vm.user, "phonenumber", $$v)
              },
              expression: "user.phonenumber",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "邮箱", prop: "email" } },
        [
          _c("el-input", {
            attrs: { maxlength: "50" },
            model: {
              value: _vm.user.email,
              callback: function ($$v) {
                _vm.$set(_vm.user, "email", $$v)
              },
              expression: "user.email",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "性别" } },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.user.sex,
                callback: function ($$v) {
                  _vm.$set(_vm.user, "sex", $$v)
                },
                expression: "user.sex",
              },
            },
            [
              _c("el-radio", { attrs: { label: "0" } }, [_vm._v("男")]),
              _c("el-radio", { attrs: { label: "1" } }, [_vm._v("女")]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-form-item",
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "mini" },
              on: { click: _vm.submit },
            },
            [_vm._v("保存")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "danger", size: "mini" },
              on: { click: _vm.close },
            },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }